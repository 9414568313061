
import * as THREE from 'three';
import { useEffect, useState, useRef } from 'react';
import { DeviceOrientationControls } from './DeviceOrientationControls.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import roomImage from './images/2294472375_1e4766bc20_c.jpg'; // Import the local image
import { supabase } from "../supabaseClient";
import { useNavigate, useLocation } from "react-router-dom";

function RoomPage() {

  const canvasRef = useRef(null);
  const location = useLocation();
  const uid = location.search.split("?")[1];
  const navigate = useNavigate();
  const [roomData, setRoomData] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [isControlsClicked, setIsControlsClicked] = useState(false);

  let controls, camera;

  let hexTube;

  const raycaster = new THREE.Raycaster();
  const pointer = new THREE.Vector2();

  const handleStart = () => {
    setIsControlsClicked(true);
    init();
  }

  useEffect(() => {
    async function fetchRoom() {
      const { data, error } = await supabase
        .from("rooms")
        .select("*")
        .eq("uid", uid)
        .single();
      if (error) {
        console.error(error);
      } else {
        console.log(data);
        setRoomData(data);
        setIsDataLoaded(true);
      }
    }
    fetchRoom();
  }, [navigate, uid]);

  const init = () => {

    camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(0, 0, 0.1);

    const scene = new THREE.Scene();

    // const geometry = new THREE.SphereBufferGeometry(500, 60, 40);
    // invert the geometry on the x-axis so that all of the faces point inward
    // geometry.scale(- 1, 1, 1);

    // const material = new THREE.MeshBasicMaterial({
    // map: new THREE.TextureLoader().load(roomImage) // Use the imported image as the texture source
    // });

    // const mesh = new THREE.Mesh(geometry, material);
    // scene.add(mesh);

    const helperGeometry = new THREE.BoxBufferGeometry(100, 100, 100, 4, 4, 4);
    const helperMaterial = new THREE.MeshBasicMaterial({ color: 0xff00ff, wireframe: true });
    const helper = new THREE.Mesh(helperGeometry, helperMaterial);
    // scene.add(helper);

    //

    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true });
    renderer.setClearColor(0xff0000, 0); // Set clear color to black (0x000000) with 0 opacity
    renderer.setClearAlpha(0);
    renderer.autoClear = false;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    // document.body.appendChild(renderer.domElement);

    // controls = new OrbitControls(camera, renderer.domElement);
    controls = new DeviceOrientationControls(camera);

    //

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    function HexagonalTube({ tubeRadius, tubeThickness, tubeLength, numSides }) {
      const hexShape = new THREE.Shape();
      const angleStep = (2 * Math.PI) / numSides;
      hexShape.moveTo(tubeRadius, 0);
      for (let i = 1; i < numSides; i++) {
        const x = tubeRadius * Math.cos(i * angleStep);
        const y = tubeRadius * Math.sin(i * angleStep);
        hexShape.lineTo(x, y);
      }
      hexShape.lineTo(tubeRadius, 0);

      const extrudeSettings = {
        depth: tubeThickness,
        bevelEnabled: false,
      };

      const geometry = new THREE.ExtrudeBufferGeometry(hexShape, extrudeSettings);
      const mesh = new THREE.Mesh(geometry, new THREE.MeshNormalMaterial({ side: THREE.DoubleSide }));

      mesh.position.set(0, 1, 0);
      mesh.rotation.x = Math.PI / 2;
      mesh.scale.z = tubeLength / tubeThickness;

      return mesh;
    }

    const generatePlane = (texture, position, rotation) => {
      const planeGeometry = new THREE.PlaneGeometry(0.5, 0.5); // Adjust the width and height as needed
      const planeMaterial = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
      const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
      planeMesh.position.copy(position);
      planeMesh.rotation.copy(rotation);

      console.log(scene)

      scene.add(planeMesh);
    };

    if (isDataLoaded) {
      console.log(roomData);

      // Add planes with image textures
      const wallTextures = [
        roomData?.wall_1_data,
        roomData?.wall_2_data,
        roomData?.wall_3_data,
        roomData?.wall_4_data,
        roomData?.wall_5_data,
        roomData?.wall_6_data,
      ];

      if (roomData.invisible_walls) {
        openCamera();
      } else {
        // scene.add(hexTube);
      }

      const positions = [
        new THREE.Vector3(0, 0, -0.5),
        new THREE.Vector3(0.433, 0, -0.25),
        new THREE.Vector3(0.433, 0, 0.25),
        new THREE.Vector3(0, 0, 0.5),
        new THREE.Vector3(-0.433, 0, 0.25),
        new THREE.Vector3(-0.433, 0, -0.25),
      ];

      const rotations = [
        new THREE.Euler(0, 0, 0),
        new THREE.Euler(0, Math.PI / 1.5, 0),
        new THREE.Euler(0, Math.PI / 3, 0),
        new THREE.Euler(0, Math.PI, 0),
        new THREE.Euler(0, 2 * Math.PI / 3, 0),
        new THREE.Euler(0, 2 * Math.PI / 1.5, 0),
      ];

      // LINK

      for (let i = 0; i < 6; i++) {
        if (roomData[`wall_${i + 1}_data`].includes("link++") &&
          !roomData[`wall_${i + 1}_data`].includes("data:image")) {
          let td = roomData[`wall_${i + 1}_data`].replace("link++", "");
          // Create a canvas element and assign an ID
          const canvas = document.createElement('canvas');
          canvas.id = `linkCanvas${i}`;
          const context = canvas.getContext('2d');
          context.font = '48px Arial';
          context.fillStyle = 'blue';
          context.fillText(td, 20, 50);
          const texture = new THREE.CanvasTexture(canvas);
          const geometry = new THREE.PlaneGeometry(0.2, 0.1);
          const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
          const plane = new THREE.Mesh(geometry, material);
          plane.name = "clickablelink" + td;
          plane.position.copy(positions[i]);
          plane.rotation.copy(rotations[i]);
          scene.add(plane);
        }
      }

      // TEXT

      for (let i = 0; i < 6; i++) {
        if (roomData[`wall_${i + 1}_data`].includes("text++")) {
          let td = roomData[`wall_${i + 1}_data`].replace("text++", "");
          // Create a canvas element and assign an ID
          const canvas = document.createElement('canvas');
          canvas.id = `textCanvas${i}`;
          const context = canvas.getContext('2d');
          context.font = '48px Arial';
          context.fillStyle = 'white';
          context.fillText(td, 20, 50);
          const texture = new THREE.CanvasTexture(canvas);
          const geometry = new THREE.PlaneGeometry(0.2, 0.1);
          const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
          const plane = new THREE.Mesh(geometry, material);
          plane.position.copy(positions[i]);
          plane.rotation.copy(rotations[i]);
          scene.add(plane);
        }
      }

      // for (let i = 0; i < 6; i++) {
      //   if (roomData[`wall_${i + 1}_data`].includes("audio++")) {
      //     let td = roomData[`wall_${i + 1}_data`].replace("audio++", "");
      //     // Create a canvas element and assign an ID
      //     const canvas = document.createElement('canvas');
      //     canvas.id = `textCanvas${i}`;
      //     const context = canvas.getContext('2d');
      //     context.font = '48px Arial';
      //     context.fillStyle = 'white';
      //     context.fillText(td, 20, 50);
      //     const texture = new THREE.CanvasTexture(canvas);
      //     const geometry = new THREE.PlaneGeometry(0.2, 0.1);
      //     const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
      //     const plane = new THREE.Mesh(geometry, material);
      //     plane.name = "clickableaudio"+td;
      //     plane.position.copy(positions[i]);
      //     plane.rotation.copy(rotations[i]);
      //     scene.add(plane);
      //   }
      // }     

      for (let i = 0; i < 6; i++) {
        if (roomData[`wall_${i + 1}_data`].includes("link++") &&
          roomData[`wall_${i + 1}_data`].includes("data:image")) {
          let td = roomData[`wall_${i + 1}_data`].substring(roomData[`wall_${i + 1}_data`].indexOf("link++") + 6);
          let imaged = roomData[`wall_${i + 1}_data`].substring(0, roomData[`wall_${i + 1}_data`].indexOf("++link"));
          console.log(imaged)
          // Create a canvas element and assign an ID
          const canvas = document.createElement('canvas');
          canvas.id = `linkCanvas${i}`;
          const img = new Image();
          img.src = imaged;
          const context = canvas.getContext('2d');
          context.drawImage(img, 0, 0, canvas.width, canvas.height);
          const texture = new THREE.CanvasTexture(canvas);
          const geometry = new THREE.PlaneGeometry(0.2, 0.1);
          const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
          const plane = new THREE.Mesh(geometry, material);
          plane.name = "clickablelink" + td;
          plane.position.copy(positions[i]);
          plane.rotation.copy(rotations[i]);
          scene.add(plane);
        }
      }

      // IMAGE

      wallTextures.forEach((wallTexture, index) => {
        if (wallTexture) {
          const textureLoader = new THREE.TextureLoader();
          textureLoader.load(wallTexture, (texture) => {
            generatePlane(texture, positions[index], rotations[index]);
          });
        }
      });
    }

    function onSceneClick(event) {
      pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
      pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(pointer, camera);
      const intersects = raycaster.intersectObjects(scene.children);
      for (let i = 0; i < intersects.length; i++) {
        if (intersects[i].object.name.includes("clickablelink")) {
          // Define the URL of the specific page you want to open
          let link = intersects[i].object.name.replace("clickablelink", "");
          const specificPageURL = link;
          console.log(specificPageURL);
          // Open the URL in a new tab
          window.open(specificPageURL, '_blank');
        }
        if (intersects[i].object.name.includes("clickableaudio")) {
          // Define the URL of the specific page you want to open
          let audiolink = intersects[i].object.name.replace("clickablelink", "");
          const audioUrl = 'uploads/' + audiolink;
          const audio = new Audio(audioUrl);
          audio.play();
        }
      }
    }

    window.addEventListener('click', onSceneClick);

    // Function to access the user's camera feed
    function openCamera() {
      // Access the user's media devices (camera)
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment' // 'environment' for back-facing camera
        }
      })
        .then(function (stream) {
          var video = document.getElementById("video");
          // Set the video source as the camera feed
          video.srcObject = stream;
          video.play();
        })
        .catch(function (error) {
          console.log("Error accessing camera:", error);
        });
    }

    hexTube = new HexagonalTube({ tubeRadius: 2, tubeThickness: 0.2, tubeLength: 2, numSides: 6 });

    return () => {
      renderer.dispose();
      // controls.dispose();
    };

  };

  function handleBack() {
    navigate('/home');
  }

  return (
    <>
      {/* <div style={{
        position: 'absolute',
        top: '10px',
        left: '10px',
        // transform: 'translate(-50%, -50%)',
        zIndex: '10000',
        backgroundColor: '#000000',
        color: '#ffffff',
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '50px',
        paddingRight: '50px',
        fontSize: '20px',
        textAlign: 'center'
      }}
        onClick={handleBack}>Home</div> */}

      {isControlsClicked ? (
        null
      ) : (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '10000',
          backgroundColor: '#000000',
          color: '#ffffff',
          paddingTop: '15px',
          paddingBottom: '15px',
          paddingLeft: '50px',
          paddingRight: '50px',
          fontSize: '20px',
          textAlign: 'center'
        }} onClick={handleStart}>Start</div>
      )}

      {/* {roomData && (
        <div style={{ height: '50px', position: 'absolute', left: '20px', top: '30px' }}>
          <h2>Room Data</h2>
          <p>ID: {roomData.id}</p>
          <p>Name: {roomData.name}</p>
          <p>Description: {roomData.description}</p>
        </div>
      )} */}
      <canvas
        ref={canvasRef}
        style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '1' }}
      />
      <video id="video" width="640" height="480"
        style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '0' }}
        autoPlay playsInline></video>
    </>
  );
}

export default RoomPage;
