import { useState, useEffect, useRef } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate, Link } from 'react-router-dom';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { FiLogOut, FiSearch } from 'react-icons/fi';
import { RiAdminLine } from 'react-icons/ri';
import { BiSolidImageAdd } from 'react-icons/bi';
// import QRCode from 'react-qr-code';
import QRCode from 'qrcode';

mapboxgl.accessToken = 'pk.eyJ1IjoiamV2b25tYWhvbmV5IiwiYSI6ImNrbjRpOThhbzBsOTkycm5xYjVodGlhZnoifQ.nUA78X2hM4qVWn-xD8l3lw';

function HomePage() {

  const buttonStyle = {
    display: 'block',
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px',
    padding: '8px',
    backgroundColor: '#fff',
    color: 'black',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  const [user, setUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [AdminLoginModalOpen, setAdminLoginModalOpen] = useState(false);
  const [clickedLocation, setClickedLocation] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [wallNumber, setWallNumber] = useState(0);

  const [text, setText] = useState('');
  const [Admintext, setAdminText] = useState('');
  const [image, setImage] = useState(null);
  const [link, setlink] = useState(null);
  const [audio, setAudio] = useState(null);
  const [isTextLocked, setIsTextLocked] = useState(false);
  const [isImageLocked, setIsImageLocked] = useState(false);
  const [isAudioLocked, setIsAudioLocked] = useState(false);

  const [tabledata, settabledata] = useState([]);

  const [invisibleWalls, setInvisibleWalls] = useState(false);

  const [dfm1, setDfm1] = useState('');
  const [dfm2, setDfm2] = useState('');
  const [dfm3, setDfm3] = useState('');
  const [dfm4, setDfm4] = useState('');
  const [dfm5, setDfm5] = useState('');
  const [dfm6, setDfm6] = useState('');

  let map, modal;

  const [qrCodeData, setQRCodeData] = useState('');

  const [adminLoggedIn, setAdminLoggedIn] = useState(true);

  useEffect(() => {
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    try {
      const { data, error } = await supabase.from('rooms').select('*');
      if (error) {
        throw error;
      }
      settabledata(data);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  useEffect(() => {
    const getuser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUser(user);
      } else {
        navigate('/')
      }
      console.log(user)
    }
    getuser();
  }, []);

  useEffect(() => {

    if (mapRef.current) return; // map already initialized

    // Create a new map instance
    map = new mapboxgl.Map({
      container: mapContainerRef.current, // Specify the container ID
      style: 'mapbox://styles/jevonmahoney/ckn4jdeho1rij17qeg7er8bln', // Specify the map style
      center: [-0.537751, 53.236861], // Specify the starting point
      zoom: 15 // Specify the zoom level
    });

    // Save the map instance to the ref
    mapRef.current = map;

    // Add click event listener to the map
    map.on('click', function (e) {
      console.log("yo")
      setClickedLocation([e.lngLat.lng, e.lngLat.lat]);
      setModalOpen(true);
    });

  }, [adminLoggedIn]);

  function handleLogout() {
    supabase.auth.signOut();
    navigate('/');
  }

  function handleAdminLogin() {
    setAdminLoginModalOpen(true);
  }

  function closeAdminLogin() {
    setAdminLoginModalOpen(false);
  }

  function handleAddImage() {

  }

  async function handleSubmitAdminModal() {
    let e;

    const check = async () => {

    };

    const getuser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        e = user.email;
        check();
      } else {
      }
    }

    getuser();

  }

  // Update the input value when the user types
  const handleAdminTextChange = (e) => {
    setAdminText(e.target.value);
  };

  function closeRoomModal() {
    setModalOpen(false);
  }

  async function handleSubmit() {
    // Get current user ID and email address
    const { data: { user } } = await supabase.auth.getUser();
    const userId = user.id;
    const userEmail = user.email;

    console.log(dfm1)

    if (name === "") {
      alert("You need to supply a name");
    } else {
      if (description === "") {
        alert("You need to supply a description");
      } else {
        // Generate a random UID
        const uid = Math.random().toString(36).substr(2, 12);

        const qrCodeBase64 = await handleGenerateQRCode(uid); // Ensure this function returns a Promise that resolves to a base64 string

        setQRCodeData(qrCodeBase64);

        const roomData = {
          uid,
          name,
          description,
          longitude: clickedLocation[0],
          latitude: clickedLocation[1],
          user_id: userId,
          user_email: userEmail,
          wall_1_data: dfm1,
          wall_2_data: dfm2,
          wall_3_data: dfm3,
          wall_4_data: dfm4,
          wall_5_data: dfm5,
          wall_6_data: dfm6,
          invisible_walls: invisibleWalls, // Add the "invisibleWalls" value
          QRCode: qrCodeBase64, // Add the QR code in base64 format
        };

        // Insert new room into 'rooms' table in Supabase
        const { data, error } = await supabase.from('rooms').insert([roomData], { returning: 'minimal' });

        if (error) {
          console.log('Error inserting room:', error.message);
        } else {
          console.log('Room inserted successfully:', data);
          alert("Room created successfully");
          // closeRoomModal();
        }
      }
    }
  }

  async function handleGenerateQRCode(uid) {
    const qrCodeURL = `https://tract.quest/room?${uid}`;
    try {
      const qrCodeBase64 = await QRCode.toDataURL(qrCodeURL);
      return qrCodeBase64;
    } catch (error) {
      console.error('Error generating QR code:', error);
      throw new Error('Failed to generate QR code');
    }
  }

  function add_wall_content(wall_number) {
    open_sub_room_modal(wall_number);
  }

  function open_sub_room_modal(wall_number) {
    setWallNumber(wall_number);
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
    resetInputs();
  }

  function renderModal() {

    if (adminLoggedIn) {

      const input =
      {
        width: '95%',
        padding: 5,
        borderRadius: 3,
        border: 0,
        marginTop: 5
      }

      return (
        <div style={{ position: 'absolute', top: 40, left: 0, height: '100%', zIndex: -1, backgroundColor: '#0AA591', padding: 10 }}>
          <p>Latitude: {clickedLocation[1]}</p>
          <p>Longitude: {clickedLocation[0]}</p>
          <label htmlFor="name">Name:</label>
          <input style={input} type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} /><br />
          <label style={{ marginTop: 10 }} htmlFor="description">Description:</label>
          <input style={input} type="text" id="description" name="description" value={description} onChange={(e) => setDescription(e.target.value)} /><br />

          <button style={buttonStyle} onClick={() => add_wall_content(1)}>Wall 1</button>
          <button style={buttonStyle} onClick={() => add_wall_content(2)}>Wall 2</button>
          <button style={buttonStyle} onClick={() => add_wall_content(3)}>Wall 3</button>
          <button style={buttonStyle} onClick={() => add_wall_content(4)}>Wall 4</button>
          <button style={buttonStyle} onClick={() => add_wall_content(5)}>Wall 5</button>
          <button style={buttonStyle} onClick={() => add_wall_content(6)}>Wall 6</button>

          <input
            type="checkbox"
            id="invisibleWallsToggle"
            checked={invisibleWalls}
            onChange={(e) => setInvisibleWalls(e.target.checked)}
          />
          <label htmlFor="invisibleWallsToggle">Invisible Walls</label>
          {qrCodeData && (
            <img src={qrCodeData} alt="QR Code" />
          )}
          <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
          <button style={buttonStyle} onClick={closeRoomModal}>Close</button>
        </div>
      );
    }
  }

  function handleSubmitModal() {
    if (image && link) {
      const linkContent = "link++" + link;
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        handleWallContent(base64Image + '++' + linkContent);
      };
      reader.readAsDataURL(image);
    }
    else if (link) {
      const linkContent = "link++" + link;
      handleWallContent(linkContent);
    } else if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        handleWallContent(base64Image);
      };
      reader.readAsDataURL(image);
    }
    else if (audio) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileName = "audio++" + audio.name;
        handleWallContent(fileName);
      };
      reader.readAsDataURL(audio);
    } else if (text) {
      const textContent = "text++" + text;
      handleWallContent(textContent);
    }
  }

  function handleWallContent(content) {
    switch (wallNumber) {
      case 1:
        setDfm1(content);
        break;
      case 2:
        setDfm2(content);
        break;
      case 3:
        setDfm3(content);
        break;
      case 4:
        setDfm4(content);
        break;
      case 5:
        setDfm5(content);
        break;
      case 6:
        setDfm6(content);
        break;
      default:
        break;
    }

    close_and_reset();
  }

  function close_and_reset() {
    setText('');
    setImage(null);
    setAudio(null);
    closeModal();
  }

  const resetInputs = () => {
    setText('');
    setImage(null);
    setAudio(null);
    setIsTextLocked(false);
    setIsImageLocked(false);
    setIsAudioLocked(false);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
    setIsImageLocked(e.target.value !== '');
    setIsAudioLocked(e.target.value !== '');
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setIsTextLocked(e.target.files[0] !== null);
    setIsAudioLocked(e.target.files[0] !== null);
  };

  const handlelinkChange = (e) => {
    setlink(e.target.value);
    setIsImageLocked(e.target.value !== '');
    setIsAudioLocked(e.target.value !== '');
  };

  const handleAudioChange = (e) => {
    setAudio(e.target.files[0]);
    setIsTextLocked(e.target.files[0] !== null);
    setIsImageLocked(e.target.files[0] !== null);
  };

  const handleRemoveImage = () => {
    setImage(null);
    setIsTextLocked(false);
    setIsAudioLocked(false);
  };

  const handleRemoveAudio = () => {
    setAudio(null);
    setIsTextLocked(false);
    setIsImageLocked(false);
  };

  const handleFormSubmit = (e) => {
    // e.preventDefault();

    // const formData = new FormData(e.target);

    // console.log(formData)

    // fetch('http://localhost:3001/upload', {
    //   method: 'POST',
    //   body: formData,
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  return (
    <div style={{ zIndex: -1 }}>
      {/* <div style={{ top: 10, left: 100, position: 'absolute' }} onClick={handleAddImage}>
        <BiSolidImageAdd />
      </div>  */}
      <p style={{ top: -10, right: 10, position: 'absolute' }}>{user?.email}</p>
      <div style={{ top: 10, left: 10, position: 'absolute' }} onClick={handleLogout}>
        <FiLogOut />
      </div>
      {/* <div style={{ top: 10, left: 50, position: 'absolute' }} onClick={handleAdminLogin}>
        <RiAdminLine />
      </div> */}
      <div ref={mapContainerRef} style={{ height: '45%', width: '45%', position: 'absolute', right: '15%', top: '15%', zIndex: -2 }}></div>

      {modalOpen && renderModal()}

      {showModal && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#fff', borderRadius: 3, backgroundColor: '#0AA591', padding: 20, zIndex: 9999 }}>
          <h1>Room Content</h1>
          <p>Wall Number: {wallNumber}</p>

          {/* Text Input */}
          <label htmlFor="text">Text:</label>
          <input type="text" id="text" name="text" value={text} onChange={handleTextChange} disabled={isTextLocked} />

          {/* Image Input */}
          <label htmlFor="image">Image:</label>
          <input type="file" accept="image/*" id="image" name="image" onChange={handleImageChange} disabled={isImageLocked} />
          {image && (
            <button style={buttonStyle} onClick={handleRemoveImage}>Remove Image</button>
          )}

          <label htmlFor="text">link:</label>
          <input type="text" id="link" name="link" value={link} onChange={handlelinkChange} />

          {/* Audio Input */}
          {/* <label htmlFor="audio">Audio:</label>
        
        <form onSubmit={handleFormSubmit} id="audio" onChange={handleAudioChange} encType="multipart/form-data">
          <input type="file" name="audioFile" />
          <button type="submit">Upload</button>
        </form> */}

          <button style={buttonStyle} onClick={handleSubmitModal}>Submit</button>
          <button style={buttonStyle} onClick={closeModal}>Close</button>
        </div>
      )}

      {AdminLoginModalOpen && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#fff', borderRadius: 3, backgroundColor: '#0AA591', padding: 20, zIndex: 9999 }}>
          <h1>Admin Pin</h1>

          <input type="password" id="adminLoginText" name="text" value={Admintext} onChange={handleAdminTextChange} disabled={isTextLocked} />

          <button style={{ display: 'block', marginTop: 5 }} onClick={handleSubmitAdminModal}>Submit</button>
          <button style={{ display: 'block', marginTop: 5 }} onClick={closeAdminLogin}>Close</button>
        </div>
      )}

      <div style={{ position: 'absolute', top: '100%' }}>
        <h1>Supabase Table</h1>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Longitude</th>
              <th>Latitude</th>
              <th>User ID</th>
              <th>User Email</th>
              <th>UID</th>
              <th>Wall 1 Data</th>
              <th>Wall 2 Data</th>
              <th>Wall 3 Data</th>
              <th>Wall 4 Data</th>
              <th>Wall 5 Data</th>
              <th>Wall 6 Data</th>
              <th>Invisible Walls</th>
              <th>QRCode</th>
              {/* Add other column headers here */}
            </tr>
          </thead>
          <tbody>
            {tabledata.map((row) => (
              <tr key={row.id}>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>{row.description}</td>
                <td>{row.longitude}</td>
                <td>{row.latitude}</td>
                <td>{row.user_id}</td>
                <td>{row.user_email}</td>
                <td>{row.uid}</td>
                <td>{renderData(row.wall_1_data)}</td>
                <td>{renderData(row.wall_2_data)}</td>
                <td>{renderData(row.wall_3_data)}</td>
                <td>{renderData(row.wall_4_data)}</td>
                <td>{renderData(row.wall_5_data)}</td>
                <td>{renderData(row.wall_6_data)}</td>
                <td>{row.invisible_walls}</td>
                <td>
                  <img src={row.QRCode} alt="QR Code" style={{ width: '100px', height: '100px' }} />
                </td>
                {/* Add other table cells for additional columns */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>

  );

  function renderData(data) {
    // Check if data starts with "data:image" to determine if it's a base64 encoded image
    if (data && data.startsWith("data:image")) {
      return "Image";
    }
    return data;
  }
  
}

export default HomePage;
